import React from 'react'
import "./CopyrightTooltip.scss"

const CopyrightTooltip = (props) => {

    let {copyrightText, classes} = props;

    let checktest = copyrightText.length>0 && copyrightText!==undefined;

    //console.log("copyrightText",copyrightText);
    //console.log("checktest",checktest);

    classes += " tooltip-bottom tooltip-mobile";
  return (
    checktest?
        <div className="tooltip-wrapper">
            <div className={classes} data-tooltip={copyrightText}>
                <text class="" focusable="false" aria-hidden="true">©</text>
            </div>
        </div>
    :
    null
  )
}

export default CopyrightTooltip

