import React from 'react';
import "./Loading.css"

const Lodaing = () => {
  return (
<div className="loader-container">
<div className="lds-ellipsis">
  <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  </div>
  );
};

export default Lodaing;