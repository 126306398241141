import React from 'react';
import { motion } from "framer-motion";
import Header from '../components/Header';
import "../styles.css"
import "./BarrierStatement.css"
import AddInnerHtml from '../helpers/AddInnerHtml';

const BarrierFreeStatement = (props) => {
    let currentEntry = props.utilities.filter(item=>item.id===203);
    let currentText = currentEntry[0].acf.text_content;
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
        <Header text={props.text}/>
        <div className="container">
        <AddInnerHtml text={currentText} classNames={"barrier-statement"}/>
        </div>
    </motion.div>
  );
};

export default BarrierFreeStatement;
