import ImageRotator from "./ImageRotator";


const RotatorWrapper = (props) => {
  let {cardArray, type} = props;
  let left = [];
  let center = [];
  let right = [];
  let newCardArray = [];

  let cardArrayCopy = cardArray;

  let originalLength = cardArray.length;
  for (let i = 0; i < originalLength; i++) {
    newCardArray.push(cardArrayCopy[i]);
  }

  let summedArray = newCardArray.concat(cardArrayCopy)

  if (type==="workAreas" || type==="projects"){
    summedArray.push(...cardArray)
  }

    let remainder = summedArray.length % 3;
    if (remainder !== 0) {
      summedArray = summedArray.slice(0, summedArray.length - remainder);
    }



    let boxOrder = [left, center, right];
    let currentBoxIndex = 0;
    for (let i = 0; i < summedArray.length; i++) {
      let currentItem = summedArray[i];
      let currentBox = boxOrder[currentBoxIndex];
      currentBox.push(currentItem);
      currentBoxIndex = (currentBoxIndex + 1) % 3;
    }


    let delay =4;
return (
  <>
    <ImageRotator 
      animationLength={12}
      delay ={delay}
      offset={3}
      cardArray={left}
      type = {type}
    />
    <ImageRotator 
      animationLength={12}
      delay ={delay}
      offset={3}
      cardArray={center}
      type = {type}
    />
    <ImageRotator 
      animationLength={12}
      delay ={delay}
      offset={3}
      cardArray={right}
      type = {type}
    />
  </>
  )
}


export default RotatorWrapper;
