import { useNavigate } from 'react-router-dom'; // version 5.2.0
import React from "react";
import "./PeopleCard.scss"
import "../pages/HomePage.css"
import chess from "../assets/chess.png"
import feather from "../assets/feather.png"
import fluid from "../assets/fluid.jpg"
import numberdpm from "../assets/numberdpm.jpeg"
import { motion } from "framer-motion"

const Card = (props) => {
    //console.log("incoming props",props);
    const {homepage} = props;
    const {photourl, id, name,photourlAI,location} = props.people;
    const navigate = useNavigate()
    let isGlitch = props.glitch;

    let displacementImages= [
        chess, feather, fluid, numberdpm
    ]

    const clickHandler = ()=>{
        navigate(`/team/${id}`);
    }

    let aiErr = photourlAI;


    
    //console.log("phturl", photourl);
    //console.log("phturlAI", photourlAI);
/* 
    const container = useRef(null);
     useEffect(() => {
        if (photourlAI===undefined){
            aiErr=photourl;
        }
        
        new hoverEffect({
          parent: container.current,
          intensity: 0.7,
          image1: `${photourl}`,
          image2: `${aiErr}`,
          width:100,
          height:100,
          displacementImage:`${displacementImages[3]}`,
        });

      }, []) 
 */


    return(
        <motion.div className="card-item" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{opacity:{ease: "linear", duration: 0.5}}} layout="position" onClick={()=>clickHandler(id)}>
                <span className="hover-tint people">
                    <img className="people-img-container homepage-people-item"
                        id="people-img-container"
                        src={photourl.url}>
                    </img>
                </span>
            {homepage?null:
            <div className="card-title">
                <h3>
                    {name}
                </h3>
                <h4>
                    {location}
                </h4>
            </div>
            }
        </motion.div>
    )
}

export default Card;