import React from 'react'
import {Routes, Route,useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import HomePage from '../pages/HomePage'
import NewsPage from '../pages/NewsPage'
import PeoplePage from '../pages/PeoplePage'
import WorkAreasPage from '../pages/WorkAreasPage'
import TeamPeopleSubPage from '../pages/PeopleSubPage'
import ProjectsPage from '../pages/ProjectsPage'
import DataProtection from '../pages/DataProtection'
import BarrierFreeStatement from '../pages/BarrierStatement'
import TheLabPage from '../pages/TheLabPage'
import Impressum from '../pages/Impressum';
import PublicationsPage from '../pages/PublicationsPage';
import WorkAreaSub from '../pages/WorkAreaSub';
import ProjectSubPage from '../pages/ProjectSubPage';

function AnimatedRoutes(props) {
    //console.log("dataLog", props);
    const location= useLocation();
 
    const {people,projects,publications,news,workAreas,utilities} = props;
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<HomePage people={people} projects={projects} publications={publications} news={news} workAreas={workAreas} text={"DIGITAL ANTHROPOLOGY LAB"} />}/>
                <Route path="/news" element={<NewsPage news={news} text={"NEWS"} utilities={utilities}/>}/>
                <Route path="/people" element={<PeoplePage people={people} projects={projects} workAreas={workAreas} publications={publications} utilities={utilities} text={"PEOPLE"}/>}/>
                <Route path="/team/:id" element={<TeamPeopleSubPage people={people} projects={projects} workAreas={workAreas} publications={publications} text={"PEOPLE"}/>}/>        
                <Route path="/workareas" element={<WorkAreasPage projects={projects} people={people} workAreas={workAreas} text={"WORK AREAS"} utilities={utilities}/>}/>
                <Route path="/workareas/:urlParam" element={<WorkAreaSub projects={projects} people={people} workAreas={workAreas} text={"WORK AREAS"}/>}/>
                <Route path="/projects" element={<ProjectsPage projects={projects} text={"PROJECTS"} workAreas={workAreas} utilities={utilities}/>}/>
                <Route path="/projects/:projectId" element={<ProjectSubPage projects={projects} people={people} workAreas={workAreas} text={"PROJECTS"}/>}/>
                <Route path="/publications" element={<PublicationsPage publications={publications} text={"PUBLICATIONS"} utilities={utilities}/>}/>
                <Route path="/thelab" element={<TheLabPage text={"THE LAB"} utilities={utilities} />}/>
                <Route path="/accessibility" element={<BarrierFreeStatement text={"ACCESSIBILITY"} utilities={utilities} />}/>  
                <Route path="/dataprotection" element={<DataProtection text={"DATA PROTECTION"} utilities={utilities}/>}/>  
                <Route path="/impressum" element={<Impressum text={"IMPRESSUM"} utilities={utilities}/>}/>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes