import {useParams} from 'react-router';
import PeopleCard from '../components/PeopleCard.js';
import './ProjectSubPage.css';
import WorkAreaList from '../components/WorkAreaList';
import { motion } from "framer-motion";
import Header from '../components/Header.js';
import AddInnerHtml from '../helpers/AddInnerHtml.js';
import CopyrightTooltip from '../components/CopyrightTooltip.js';

function ProjectPageSub(props) {

  const { projectId } = useParams();

  let idNumber = parseInt(projectId);
  const {projects, people, workAreas} = props;

  let filteredProject = projects.filter(project=>
    project.id===idNumber
    );

  let currentProject = filteredProject[0].acf;

//Related Work Area filter

  let relatedWorkAreas = workAreas.filter((workArea)=>{
    return(currentProject.workAreas.includes(workArea.acf.title))    
  }
  )

    let relatedPeople =
    people.filter((person)=>{
    if (
        currentProject.projectLeader.indexOf(person.acf.name) !== -1 ||
        currentProject.projectResearchers.indexOf(person.acf.name) !== -1 ||
        currentProject.projectCoLeader.indexOf(person.acf.name) !== -1 ||
        currentProject.relatedPeople.some(relatedPeople => relatedPeople.ID === person.id)
        )
    {
        return(person)
    }
    return(null)
  }
    )
//Sort related people's family names by ABC order
    relatedPeople.sort(function(a, b) {
      var nameA = a.acf.name.split(' ').pop().toUpperCase(); // get the family name from the last word of the name string and convert to uppercase
      var nameB = b.acf.name.split(' ').pop().toUpperCase(); // same for the second object
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    

    let relatedPeopleList = relatedPeople.map(people => 
        <PeopleCard
          people = {people.acf}
          projects = {projects}
          key = {people.id}
          />
      )

  return (
      <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
        <Header text={props.text}/>
        <div className="container">
          <div className='info-wrapper'>
            <div className="sub-img-wrapper">
              <div className="img-tooltip-wrapper">
              <span className="project-img-bg">
              <img className="project-img" src={currentProject.projectimg.url}/>
              </span>
              <CopyrightTooltip classes="" copyrightText={currentProject.projectimg.caption}/>
              </div>
            </div>
            <h1 className="subpage-title">{currentProject.fullTitle}</h1>
            <h3 className="subpage-title">{currentProject.subTitle}</h3>
            <AddInnerHtml text={currentProject.projectDesc} classNames="subpage-description"/>
{/*             <p>
              Image copyright: {currentProject.projectimg.caption}
            </p> */}
          </div>

        <section className="sub-page-detail" style={{border:"5px solid var(--projects-color)"}}>
            <div className="subpage-details-wrapper">
              {currentProject.funding!==""&&
              <p style={{marginTop:0}}>
                <b>Funding</b>: {currentProject.funding}
              </p>
              }
              {currentProject.projectLeader!==""&&
              <p>
                <b>{currentProject.projectLeader.length>=2?"Project leaders":"Project leader"}</b>: {currentProject.projectLeader}
              </p>
              }
              {currentProject.projectCoLeader!==""&&
                  <p>
                    <b>{currentProject.projectCoLeader.length>=2?"Project co-leaders":"Project co-leader"}</b>: {currentProject.projectCoLeader}
                  </p>
                  }
              {currentProject.projectResearchers!==""&&
                  <p>
                    <b>{currentProject.projectResearchers.length>=2?"Project researchers":"Project researcher"}</b>: {currentProject.projectResearchers}
                  </p>
              }             
              {currentProject.fundingStart!==""&&
              <p>
                <b>Duration</b>: {currentProject.fundingStart.slice(0,4)} - {currentProject.fundingEnd.slice(0,4)}
              </p>
              }
              {(currentProject.additional_info!=="")?
                <AddInnerHtml text={currentProject.additional_info}/>
              :null
              }
              {(currentProject.link!=="")?
              <p>
                <b><a href={currentProject.link} rel="noopener noreferrer" target="_blank" onClick={(event) => { event.preventDefault(); window.open(currentProject.link, '_blank', 'noopener noreferrer'); }}>Link to Project Page with further details<span className="link-span"> ⎋ </span></a></b>
              </p>
              :null
              }
            </div>
        </section>
  
        {relatedWorkAreas?
          <section id="workareas" style={{width:"100%"}}>
            <h1 className="section-title sub">Related Work Areas</h1>
              <WorkAreaList workAreas={relatedWorkAreas}/>
          </section>
          :
          null
        }

        {relatedPeopleList.length>0?
          <section id="people" style={{width:"100%"}}>
            <h1 className="section-title sub">Related People</h1>
            <div className="card-container">
              {relatedPeopleList}
            </div>
          </section>
          :
            null
          }
        </div>
        </motion.div>
    )

}
export default ProjectPageSub;
