import React from 'react'
import AddInnerHtml from '../helpers/AddInnerHtml';

const PublicationCard = ({ publication }) => {
    //console.log("publication data",publication);
    let publicationText = publication.acf.publication;

  return (
    <div className="publication-card">
      <AddInnerHtml text={publicationText} classNames={"publication-card-content"}/>
    </div>
  );
};

export default PublicationCard