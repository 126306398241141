import {React, useState, useEffect} from 'react';
import "./Navbar.css"
import NavBarSideMobile from './NavBarSideMobile';
import NavBarTopMenu from './NavBarTopMenu';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  const handleClick = ()=>{
    setShowMenu(!showMenu);
    //console.log("HAND");
  }

  document.addEventListener('click', handleClickOutside);

  function handleClickOutside(event) {
    event.preventDefault();
    const menuBar = document.querySelector('.menu-bar-mobile');
    const menuIcon = document.querySelector('.menu-icon');
    if (menuBar!==undefined && menuBar!==null){
      menuBar.style.transition = "transform 0.7s ease-in-out"
    } else {
      setTimeout(() => {
        handleClickOutside(event)
      }, 300);
    }

    if (isMobile&&menuBar!==null&&menuIcon!==null){
    if (event.target !== menuBar &&!menuIcon.contains(event.target) && event.target!==menuIcon ) {
      //console.log("kint", !menuBar.contains(event.target) && event.target !== menuBar && !menuIcon.contains(event.target));
      setShowMenu(false);
      return
    }
    //console.log("semmi");
    }
  }

  useEffect(() => {
    const element = document.querySelector(".menu-bar-mobile");
    if (element!==undefined && element!==null){
      element.style.transition = "transform 0.7s ease-in-out"
    }
    
      return
  }, []);
 
  useEffect(() => {
    setIsMobile(window.innerWidth <= 790);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 790);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

   return (
    <>
     <header className="header" style={isMobile?{justifyContent:"center"}:{justifyContent:"flex-end"}}>
     <input className="menu-btn" type="checkbox" id="menu-btn"/>
        <label className="menu-icon" style={!isMobile?{display:"none"}:{display:"unset", float:"right"}} onClick={handleClick} for="menu-btn">
          <span className="navicon"></span>
        </label>
        {isMobile?
         <NavBarSideMobile showMenu={showMenu}/>
        :
       <NavBarTopMenu showMenu={showMenu}/>
        }
        </header>
    </>
   )
};
  
export default Navbar;