import React, {useState,useEffect } from 'react';
import PeopleCardHover from '../components/PeopleCard.js';
import { LayoutGroup } from "framer-motion"
import '../pages/PeoplePage.css';
import { motion } from "framer-motion";
import Header from "../components/Header"
import AddInnerHtml from '../helpers/AddInnerHtml.js';


const PeoplePage = (props) => {
  //console.log("people props",props);

  const {people} = props;

  let currentEntry = props.utilities.filter(item=>item.id===344);
  let currentText = currentEntry[0].acf.text_content;

  const memberFilterValues = [
    "Coordination",
    "Full Members",
    "Associate Members"
  ];

    const [memberFilterValue, setMemberFilterValue] = useState([]);
    const [memberFilterValueButton, setMemberFilterValueButton] = useState([]);
 
    useEffect(() => {
      if (memberFilterValueButton.length===0){
        setMemberFilterValue(memberFilterValues)
      } else {
        setMemberFilterValue(memberFilterValueButton)
      }},[memberFilterValueButton])

    let sessionProjects = JSON.parse(sessionStorage.getItem('projects'));


    const filteredPeople = people.filter((person) => 
    (memberFilterValue.some((value) => person.acf.status.includes(value)))
  );

  
  const positionOrder = {
    "Associate Members": 2,
    "Full Members": 1,
    "Coordination": 0, // assign lower value to prioritize "Coordinator" positions
  };
  
  let sortedPeople = filteredPeople.sort((a, b) => {
    // Sort by position
    const aPositions = a.acf.status; // assume status is an array of positions
    const bPositions = b.acf.status; // assume status is an array of positions
  
    // check if either a or b has a "Coordinator" position, and prioritize them accordingly
    const aIsCoordinator = aPositions.some((p) => p === "Coordination");
    const bIsCoordinator = bPositions.some((p) => p === "Coordination");
    if (aIsCoordinator && !bIsCoordinator) {
      return -1;
    }
    if (!aIsCoordinator && bIsCoordinator) {
      return 1;
    }
  
    // if both have "Coordinator" or neither do, sort by position order
    const aPosition = Math.min(...aPositions.map((p) => positionOrder[p]));
    const bPosition = Math.min(...bPositions.map((p) => positionOrder[p]));
    if (aPosition !== bPosition) {
      return aPosition - bPosition;
    }
  
    // If positions are equal, sort by name
    const aName = a.acf.name.toUpperCase();
    const bName = b.acf.name.toUpperCase();
    const aLastName = aName.split(" ").pop(); // get last word as family name
    const bLastName = bName.split(" ").pop(); // get last word as family name
    if (aLastName < bLastName) {
      return -1;
    }
    if (aLastName > bLastName) {
      return 1;
    }
    return 0;
  });

      const handleMemberButtonClick = (value) => {
        if (memberFilterValueButton.includes(value)) {
          setMemberFilterValueButton(memberFilterValueButton.filter((v) => v !== value));
        } else {
          setMemberFilterValueButton([...memberFilterValueButton,value]);
        }
      };


      const renderStatusFilterButtons = () => {
        return memberFilterValues.map((button) => {
          const active = memberFilterValueButton.includes(button);
          const className = active ? `filter-button people active ${button}` : `filter-button people ${button.short}`;
          return (
            <>
              <button
                key={button}
                className={className}
                onClick={() => {handleMemberButtonClick(button)}}
              >
                {button}
              </button>
            </>
          );
        });
      };
    
    let cards = [];

    if (typeof(sortedPeople)=='object' && sortedPeople.length >0){
    cards = sortedPeople.map(people =>
        <PeopleCardHover 
          people = {people.acf}
          projects = {sessionProjects}
          glitch = {false}
          key = {`peepid:${people.id}`}
          />
        
      )}

return (
      <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
        <Header text={props.text}/>
        <div className="container">
        <div className="subPageDesc">
            <AddInnerHtml text={currentText}/>
          </div>
        <div className="peopleCardContainer">
          <div className="people-filter-wrapper peoplepage" style={{justifyContent:"space-around"}}>
            {renderStatusFilterButtons()}
          </div>
          <div className="card-container people-height" style={{minHeight:"700px"}}>
            <LayoutGroup layoutRoot layout="position">
              {cards}
            </LayoutGroup> 
          </div>
        </div>
        </div>
      </motion.div>
  )
};
  
export default PeoplePage;