import React from 'react';

const AddInnerHtml = ({text, classNames}) => {
  if (classNames === undefined) {
    classNames="";
  }

  classNames += " WYSIWYG"

  const handleAnchorClick = (e) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) return;
    e.preventDefault();
    if (targetLink.href.startsWith("mailto:")) {
      window.location.href = targetLink.href;
    } else {
      window.open(targetLink.href, "_blank", "noopener");
    }
  };


  return (
    <>
      <div
        onClick={handleAnchorClick}
        className={classNames}
        dangerouslySetInnerHTML={{ __html: text}}
      >
      </div>
    </>
  );
};

export default AddInnerHtml;