import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

import Header from '../components/Header';
import RotatorWrapper from '../components/RotatorWrapper';

import "./HomePage.css"
import "../styles.css"

const Home = (props) => {

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
      <Header text={props.text}/>
        <div className="container">
        <section id="workareas">
          <h1 className="section-title">Work Areas</h1>
          <div className="home-card-container">
            <RotatorWrapper 
              cardArray={props.workAreas}
              type={"workAreas"}
              />
          </div>
            <div className="moreButton">
            <Link to="/workareas">
              <button>Learn more</button>
            </Link>
            </div>
        </section>

        <section id="people">
          <h1 className="section-title">People</h1>
          <div className="home-card-container">
            <RotatorWrapper 
              cardArray={props.people}
              type={"people"}
              />
          </div>
            <div className="moreButton">
              <Link to="/people">
                <button>Meet the team</button>
              </Link>
            </div>
        </section>
 
        <section id="projects">
          <h1 className="section-title">Projects</h1>
          <div className="home-card-container project-home">
            <RotatorWrapper 
              cardArray={props.projects}
              type={"projects"}
              />
          </div>
          <div className="moreButton" style={{marginTop:"0.7%"}}>
            <Link to="/projects">
              <button>See all projects</button>
            </Link>
            </div>
        </section>

        </div>
    </motion.div>
)};
  
export default Home;