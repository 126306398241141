import React from "react";
import WorkAreaList from "../components/WorkAreaList";
import "./WorkAreasPage.css"
import { motion } from "framer-motion";
import Header from "../components/Header";
import AddInnerHtml from "../helpers/AddInnerHtml";

function WorkAreasPage({ workAreas, text, utilities }) {
  let currentEntry = utilities.filter(item=>item.id===346);
  let currentText = currentEntry[0].acf.text_content;

  return (
      <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
        <Header text={text}/>
        <div className="container">
          <div className="subPageDesc">
            <AddInnerHtml text={currentText}/>
            </div>
              <div className="workAreaContainer">
                <WorkAreaList workAreas={workAreas}/>
              </div>
          </div>
        </motion.div>
  );
};

export default WorkAreasPage;

