import React from 'react'
import { NavLink } from 'react-router-dom'

function NavBarSideMobile({showMenu}) {
  return (
    <ul className={`menu menu-bar-mobile ${showMenu?"active":""}`}>
        <li>
              <NavLink to="/">
                <h1>
                Home
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/thelab">
                <h1>
                The Lab
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/news">
                <h1>
                News
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/workareas">
                <h1>
                Work Areas
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/projects">
                <h1>
                Projects
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/people">
                <h1>
                People
                </h1>
              </NavLink>
            </li>
            <li>
              <NavLink acticeclassname="active" to="/publications">
                <h1>
                Publications
                </h1>
              </NavLink>
              </li>
        </ul>
  )
}

export default NavBarSideMobile