import React, { useState, useEffect } from "react";
import ProjectCardList from '../components/ProjectCardList';
import { LayoutGroup } from "framer-motion"
import "./ProjectsPage.css"
import { motion } from "framer-motion";
import Header from "../components/Header";
import AddInnerHtml from "../helpers/AddInnerHtml";

function ProjectFilter(props) {

  const {projects,workAreas} = props;
  let currentEntry = props.utilities.filter(item=>item.id===345);
  let currentText = currentEntry[0].acf.text_content;

  const workareaValues = [...new Set(workAreas.map(obj => obj.acf.title).reduce((acc, val) => acc.concat(val), []))];
  const projectStatusValues = [...new Set(projects.map(obj => obj.acf.project_status).reduce((acc, val) => acc.concat(val), []))];
  const fundingValues = [...new Set(projects.map(obj => obj.acf.project_type).reduce((acc, val) => acc.concat(val), []))];

  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
    //console.log("isActive",isActive);
  };


document.addEventListener('click', handleClickOutside);

function handleClickOutside(event) {
  event.preventDefault();
  const panel = document.querySelector('.panel');
  const filterAccordion = document.querySelector('.filter-accordion');
  if (isMobile&&panel!==null&&filterAccordion!==null){
  if (!panel.contains(event.target) && event.target !== panel && !filterAccordion.contains(event.target)) {
    //console.log("kint", !panel.contains(event.target) && event.target !== panel && !filterAccordion.contains(event.target));
    //console.log("panel", panel, "event:", event.target);
    setIsActive(false);
  }
  }
}

  fundingValues.sort((a, b) => {
    if (a === "Projects at LUI") {
      return -1; // a comes first
    }
    else if (b === "Projects at LUI") {
      return 1; // b comes first
    }
    else {
      return 0; // a and b stay in their current order
    }
  });


  useEffect(() => {
    (window.innerWidth <= 790)? setIsMobile(true):setIsMobile(false)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [workAreaFilterValue, setWorkAreaFilterValue] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [fundingFilterValue, setFundingFilterValue] = useState([]);

  const [workAreaFilterValueButton, setWorkAreaFilterValueButton] = useState([]);
  const [statusFilterValueButton, setStatusFilterValueButton] = useState([]);
  const [fundingFilterValueButton, setFundingFilterValueButton] = useState([]);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);
  
useEffect(() => {
  if (workAreaFilterValueButton.length===0){
    setWorkAreaFilterValue(workareaValues)
  } else {
    setWorkAreaFilterValue(workAreaFilterValueButton)
  }

  if (statusFilterValueButton.length===0){
    setStatusFilterValue(projectStatusValues)
  } else {
    setStatusFilterValue(statusFilterValueButton)
  }

  if (fundingFilterValueButton.length===0){
    setFundingFilterValue(fundingValues)
  } else {
    setFundingFilterValue(fundingFilterValueButton)
  }


}, [fundingFilterValueButton,statusFilterValueButton,workAreaFilterValueButton])

  const filteredProjects = projects.filter((project) => 
    (workAreaFilterValue.some((value) => project.acf.workAreas.includes(value))) && 
    (statusFilterValue.some((value)=>project.acf.project_status.includes(value))) &&
    (fundingFilterValue.some((value)=>project.acf.project_type.includes(value)))
  );

  const handleWorkAreaButtonClick = (value) => {
      if (workAreaFilterValueButton.includes(value)) {
        setWorkAreaFilterValueButton(workAreaFilterValueButton.filter((v) => v !== value));
      } else {
        setWorkAreaFilterValueButton([...workAreaFilterValueButton, value]);
      }
  };

  const handleDateButtonClick = (value) => {
    if (statusFilterValueButton.includes(value)) {
      setStatusFilterValueButton(statusFilterValueButton.filter((v) => v !== value));
    } else {
      setStatusFilterValueButton([...statusFilterValueButton,value]);
    }

  };

      const handleFundingButtonClick = (value) => {
        if (fundingFilterValueButton.includes(value)) {
          setFundingFilterValueButton(fundingFilterValueButton.filter((v) => v !== value));
        } else {
          setFundingFilterValueButton([...fundingFilterValueButton, value]);
        }
      };

  const renderDateFilterButtons = () => {
    return projectStatusValues.map((button) => {
      const active = statusFilterValueButton.includes(button);
      const className = active ? "filter-button-projects activeDate date-filter group-1" : `filter-button-projects   ${isMobile?"whiteBg":""} date-filter group-1`;
      return (
        <>
          <button 
            key={button.title}
            className={className}
            onClick={() => handleDateButtonClick(button)}
          >
            {button}
          </button>
        </>
      );
    });
  };

  const renderTypeFilterButtons = () => {
    return fundingValues.map((button) => {
      const active = fundingFilterValueButton.includes(button);
      const className = active ? "filter-button-projects activeType type" : `filter-button-projects ${isMobile?"whiteBg":""} type`;
      return (
        <>
          <button
            key={button}
            className={className}
            onClick={() => handleFundingButtonClick(button)}
          >
            {button}
          </button>
        </>
      );
    });
  };

  const renderWorkAreaFilterButtons = () => {
    return workAreas.map((workArea) => {
      const active = workAreaFilterValueButton.includes(workArea.acf.title);
      const className = active ? "filter-button-projects active" : `filter-button-projects  ${isMobile?"whiteBg":""} topic`;
      return (
        <>
          <button
            key={workArea.acf.id}
            className={className}
            onClick={() => handleWorkAreaButtonClick(workArea.acf.title)}
          >
            {workArea.acf.title}
          </button>
        </>
      );
    });
  };


  const rederMobileFilterButton = () => {
      const className = mobileFilterVisible ? `filter-button-projects date-filter active` : `filter-button-projects date-filter`;
      return (
        <>
          <button
            key="mobileFilter"
            className={className}
            style={{width:"80.37%"}}
            onClick={() => {
              toggleAccordion();
              setMobileFilterVisible(!mobileFilterVisible);}
            }
          >
            {!isActive? "Show Filters":"Hide Filters"}
          </button>
        </>
      );
    };

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
      <Header text={props.text}/>
      <div className="container">

      <div className="project-page-container">

        <div className="subPageDesc">
          <AddInnerHtml text={currentText}/>
        </div>
        {isMobile?
        <>
          <div className="filter-accordion" id="filter-box" onClick={toggleAccordion}>
          {rederMobileFilterButton()}
          </div>

          <div className="accordion">
{/*           <div className="filter-accordion" id="filter-box" onClick={toggleAccordion}>
            <text className="side-filter">Filters</text>  
            <text className={`filter-arrow ${isActive?"active":""}`}>{">"}</text>
          </div> */}

          <div className={`container panel ${isActive? 'active' : ''}`}>
            <div className="filter-wrapper project mobile">
              <div className="filter-category">
                {renderDateFilterButtons()}
                {renderTypeFilterButtons()}
              </div>
              <div className="filter-category">
                {renderWorkAreaFilterButtons()}
              </div>
            </div>
          </div>
          </div>
          </>
        :
        <div className="filter-wrapper project">
          <div className="filter-category">
            {renderDateFilterButtons()}
            {renderTypeFilterButtons()}
            </div>
          <div className="filter-category">
          </div>
          <div className="filter-category">
            {renderWorkAreaFilterButtons()}
          </div>
        </div>
        }
      <div className="project-list-container">
        <LayoutGroup>
          <ProjectCardList isMobile={isMobile} projects={filteredProjects}/>
        </LayoutGroup>
      </div>
      </div>
      </div>

    </motion.div>
  );
}

export default ProjectFilter;
