import {useParams} from 'react-router';
import ProjectCardList from '../components/ProjectCardList';
import WorkAreaList from '../components/WorkAreaList';
import './PeopleSubPage.css';
import { motion } from "framer-motion";
import Header from '../components/Header';
import PublicationCard from '../components/PublicationCard';
import AddInnerHtml from '../helpers/AddInnerHtml';
import CopyrightTooltip from '../components/CopyrightTooltip';

const TeamPeopleSubPage = (props) => {

    const {people, projects, workAreas, publications} = props;

    const { id } = useParams();
    let currentMember = people.filter(member=>member.acf.id===id);
    //console.log("currentMember",currentMember);

    const relatedWorkAreas = workAreas.filter(workArea => {
        return currentMember[0].acf.workAreas.some(relatedWorkArea => relatedWorkArea.id === workArea.id);
    });

    const relatedProjects = projects.filter(project => {
        return currentMember[0].acf.relatedProjects.some(relatedProject => relatedProject.id === project.id);
      });

    const relatedPublications = publications.filter(publication => {
        return currentMember[0].acf.relatedPublications.some(relatedPublication => relatedPublication.id === publication.id);
    });
        const renderPublications = relatedPublications.map(publication=>
            <PublicationCard publication={publication} key={publication.id}/>
        )

    const additionalInfo = currentMember[0].acf.additional_info;

    return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
            <Header text={props.text}/>
            <div className="container">
                <div className='info-wrapper'>
                        <div className="peoplepeople-img-container">
                            <div className="img-tooltip-wrapper">
                                <img className="peopleImgInside" src={currentMember[0].acf.photourl.url}/>
                                <CopyrightTooltip copyrightText={currentMember[0].acf.photourl.caption}/>
                            </div>
                        </div>
                    <h1 className="people-subpage-title">{currentMember[0].acf.name}</h1>
                    <h3 className="member-location">{currentMember[0].acf.location}</h3>
                    <p className='subpage-description WYSIWYG'>
                    <AddInnerHtml text={currentMember[0].acf.bio}/>
                    </p>
                </div>

            <section className="sub-page-detail" style={{border:"5px solid var(--people-color)"}}>
                <div className="subpage-details-wrapper">
                    <p><b>Affiliation:</b> {currentMember[0].acf.department}</p>
                    <p><b>Position:</b> {currentMember[0].acf.position}</p>
                    <p><b>Email:</b> <a href={`mailto:${currentMember[0].acf.email}`} onClick={(event) => { event.preventDefault(); window.open(`mailto:${currentMember[0].acf.email}`)}}>{currentMember[0].acf.email}</a></p>
                    {currentMember[0].acf.link!==""&&
                    <>
                        <p className="member-link">
                            <b><a href={currentMember[0].acf.link} rel="noopener noreferrer" target="_blank" onClick={(event) => { event.preventDefault(); window.open(currentMember[0].acf.link, '_blank', 'noopener noreferrer'); }}>Link to full academic profile<span className="link-span"> ⎋ </span></a></b>
                        </p>
                    </>
                    }
                <AddInnerHtml text={additionalInfo} classNames={"additional-info"}/>
                </div>
            </section>

            {relatedWorkAreas.length>0?
                <section id="workareas" className="people-sub">
                        <h1 className="section-title sub">Related Work Areas</h1>
                        <WorkAreaList workAreas={relatedWorkAreas}/>
                </section>
                :
                null
            }

            {relatedProjects.length>0?
                <section id="projects" className="people-sub">
                    <h1 className="section-title sub">Related Projects</h1>
                    <ProjectCardList projects={relatedProjects}/>
                </section>
                :
                null
            }

            {relatedPublications.length>0?
            <section id="publications" className="people-sub" style={{height:"auto"}}>
                <div className="publications-wrapper">
                    <h1 className="section-title sub">Related Publications</h1>
                    {relatedPublications.length>0?
                    <>
                        {renderPublications}
                    </>
                    :null}
                </div>
            </section>
            :
            null}
            
            </div>
     </motion.div>
  )
}

export default TeamPeopleSubPage;