import React from "react";
import "./NewsFeed.css";
import AddInnerHtml from "../helpers/AddInnerHtml";

const NewsFeed = (props) => {

    const {news} = props;
    //console.log("news", news);
    const pinnedNews = news.filter(item => item.acf.pinned === true)
                          .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));
    const unpinnedNews = news.filter(item => !item.acf.pinned)
                            .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));
    const sortedNews = pinnedNews.concat(unpinnedNews);

    //console.log("sortedNews", sortedNews);

    function formatMonth(dateString,isBr) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 10);
      //console.log("dateString", dateString);
      //console.log("year",year);
      //console.log("month",month);
      //console.log("day",day);

      const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
    
      const formattedMonth = monthNames[parseInt(month) - 1];
      //console.log("formattedMonth",formattedMonth);
      return (
        isBr?
          <>
            <p>{year}</p>
            <p>{formattedMonth}</p>
            <p>{day}</p>
          </>
          :
          <div className="date-small">{year}/{formattedMonth}/{day}</div>
          
      );
    }


  return (
    <div className="news-feed">
      {sortedNews.map((item) => (
        <>
        <div className="news-item" key={item.id}>
        <div className="news-date">
              {formatMonth(item.acf.pubDate,true)}
          </div>
          {item.acf.image?<img className="news-img" src={item.acf.image}/>:null}
          <div className="news-content">
            <p className="news-title">{item.acf.title}</p>
            <AddInnerHtml text={item.acf.content}/>
          </div>
        </div>
        </>
      ))}
    </div>
  );
      }

export default NewsFeed;
