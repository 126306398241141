import "./ProjectCardList.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const ProjectCard = (props) => {
  
  const {id, project, homepage} = props;
  //console.log("homepage project", props);

  const navigate = useNavigate();

      let cardStyle = {};

    return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{opacity:{ease: "linear", duration: 0.5}}} layout="position" className="card-item" onClick={() => navigate(`/projects/${id}`)}>
        <span className="project-img-bg">
          <img className="project-img background-tint" src={project.projectimg.url} key={project.id} style={cardStyle}>
          </img>
          </span>
          {homepage?null:
          <div className="card-title homepage-project-title">
            <h3>
              {project.shortTitle}
            </h3>
          </div>
          }
        </motion.div>
    )
}

function ProjectCardList(props) {

  const {projects, homepage} = props;

    //console.log("projects props",projects);

  let sortedProjects =  projects.sort((a, b) => {
    const titleA = a.acf.fullTitle.toUpperCase();
    const titleB = b.acf.fullTitle.toUpperCase();
  
    if (titleA < titleB) {
      return -1; // a comes first
    } else if (titleA > titleB) {
      return 1; // b comes first
    } else {
      return 0; // a and b stay in their current order
    }
  });

  return (
    <motion.div layout="position" layoutRoot className="card-container" /* style={{height:`${(sortedProjects/2)*300}"px"}`}} */>
      {sortedProjects.map((project) => (
        <ProjectCard 
          key={project.id} 
          id={project.id}
          project = {project.acf}
          homepage = {homepage}
        />
      ))}
    </motion.div>
  );
}

export default ProjectCardList;