import React, { useState } from "react";

function ColorModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [mainBg, setMainBg] = useState("#000000");
  const [peopleColor, setPeopleColor] = useState("#E9B872");
  const [projectsColor, setProjectsColor] = useState("#000000");
  const [workareaColor, setWorkareaColor] = useState("#000000");

  window.addEventListener('keydown', handleKeyPress);


  // function to handle key press and open modal
  function handleKeyPress(e) {
    if (e.key === "1") {
      setIsOpen(!isOpen);
    }
  }

  // function to handle form submission
  function handleSubmit(e) {
    e.preventDefault();

    // convert HEX colors to rgba values
    const mainBgRGBA = hexToRGBA(mainBg);
    const peopleColorRGBA = hexToRGBA(peopleColor);
    const projectsColorRGBA = hexToRGBA(projectsColor);
    const workareaColorRGBA = hexToRGBA(workareaColor);

    // update CSS variables with new rgba values
    document.documentElement.style.setProperty("--people-color",peopleColorRGBA.rgba);
    document.documentElement.style.setProperty("--people-color-shine",peopleColorRGBA.rgbas);


    document.documentElement.style.setProperty("--projects-color",projectsColorRGBA.rgba);
    document.documentElement.style.setProperty("--projects-color-shine",projectsColorRGBA.rgbas);

    document.documentElement.style.setProperty("--workarea-color", workareaColorRGBA.rgba);
    document.documentElement.style.setProperty("--workarea-color-shine", workareaColorRGBA.rgbas);

    // close modal
    setIsOpen(false);
  }

  // function to convert HEX colors to rgba values
  function hexToRGBA(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return {rgba:`rgba(${r}, ${g}, ${b}, 1)`, rgbas:`rgba(${r},${g},${b},0.5)`}
  }

  return (
    <div>
          {isOpen && (
        <div className="modal">
          <form className="form-wrapper" onSubmit={handleSubmit}>
            <div>
            <label>Main bg:</label>
            <input
              type="text"
              value={mainBg}
              onChange={(e) => setMainBg(e.target.value)}
            />
            </div>

            <div>
            <label>People color:</label>
            <input
              type="text"
              value={peopleColor}
              onChange={(e) => setPeopleColor(e.target.value)}
            />
            </div>

            <div>
            <label>Projects color:</label>
            <input
              type="text"
              value={projectsColor}
              onChange={(e) => setProjectsColor(e.target.value)}
            />
            </div>
            <div>
            <label>Workareas color:</label>
            <input
              type="text"
              value={workareaColor}
              onChange={(e) => setWorkareaColor(e.target.value)}
            />
            </div>
            <br></br>
            <button type="submit" onClick={handleSubmit}>Save Changes</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ColorModal;