import React from "react";
import { useNavigate } from 'react-router-dom'; 

const ImageRotator = (props) => {
  const {type, animationLength,cardArray,delay, offset} = props;


  let cardStyle = "";
  const navigate = useNavigate()

  let images = cardArray.map((card, index)=>{


    let animationDuration = cardArray.length*3+"s";
    let animationDelay = index*3+offset+"s";

    
    if 
    (type === "workAreas") {
      return(
      <img
        className="homepage-wa-item" 
        key={index} 
        data-copyright={`"filename":"${card.acf.imgUrl.filename}","author":"${card.acf.imgUrl.caption}","link:"${card.acf.imgUrl.url}"`}
        src={card.acf.imgUrl.url}
        style={{
          animation: `xfade infinite`,
          animationDuration: animationDuration,
          animationDelay: animationDelay,
          zIndex:50,
          }}
        //onClick={() => navigate(`/workareas/${card.acf.titleUrl}`)}>
        onClick={() => navigate(`/workareas/`)}>
      </img>
      )
    } else if 
    (type === "people") {
      return(
        <img
          className="homepage-people-item"
          key={index}
          src={card.acf.photourl.url}
          style={{
            animation: `xfade infinite`,
            animationDuration: animationDuration,
            animationDelay: animationDelay,
            zIndex:40-index,
            }}
          //onClick={()=>navigate(`/team/${card.acf.id}`)}>
          onClick={() => navigate(`/people/`)}>
        </img>
        )
    } else if
        (type === "projects") {
          return(
          <div className="homepage-projects-item"
            key={index} 
            style={{
              animation: `xfade infinite`,
              animationDuration: animationDuration,
              animationDelay: animationDelay,
              zIndex:40-index,
            }}
            //onClick={() => navigate(`/projects/${card.id}`)}>
            onClick={() => navigate(`/projects/`)}>
            <span className="home-project-img-bg" style={{width:"108%"}}>
            <img className="project-img background-tint" src={card.acf.projectimg.url} key={index}>
            </img>
            </span>
          </div>
          )
    }

    }
   )


  

  return (
    <div className="home-card-item">
      {images}
    </div>
  );
};

export default ImageRotator;
