import {React, useEffect} from 'react';
import { Link } from 'react-router-dom';

import "./Footer.css";


function Footer() {

  useEffect(() => {
    const elements = document.querySelectorAll('[data-copyright]');
    const data = Array.from(elements).map(element => element.dataset);
  }, []);

  return (
    <footer>
      <nav>
        <ul>
          <li><Link to="/dataprotection">Data protection</Link></li>
          <li><Link to="/impressum">Impressum</Link></li>
          <li><Link to="/accessibility">Accessibility statement</Link></li>
          <li><a href="https://www.facebook.com/digital.anthropology.lab" rel="noopener noreferrer" target="_blank" onClick={(event) => { event.preventDefault(); window.open("https://www.facebook.com/digital.anthropology.lab", '_blank', 'noopener noreferrer'); }}>Facebook</a></li>
          <li><a href="https://listserv.uni-tuebingen.de/mailman/listinfo/digital.anthropology.lab" rel="noopener noreferrer" target="_blank" onClick={(event) => { event.preventDefault(); window.open("https://listserv.uni-tuebingen.de/mailman/listinfo/digital.anthropology.lab", "_blank", "noopener"); }}>Newsletter</a></li>
          <li><a href="mailto:digital.anthropology.lab@lui.uni-tuebingen.de" onClick={(event) => { event.preventDefault(); window.open("mailto:digital.anthropology.lab@lui.uni-tuebingen.de"); }}>Contact us</a></li>
        </ul>
      </nav>
    </footer>
  );
}


export default Footer;