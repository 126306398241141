import React from 'react';
import NewsFeed from '../components/NewsFeed';
import "./NewsPage.css"
import { motion } from "framer-motion";
import Header from '../components/Header';
import AddInnerHtml from '../helpers/AddInnerHtml';

const NewsPage = (props) => {

  let currentEntry = props.utilities.filter(item=>item.id===365);
  let currentText = currentEntry[0].acf.text_content;

  const {news} = props;

 return (
      <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
        <Header text={props.text}/>
        <div className="container">
        <div className="subPageDesc">
            <AddInnerHtml text={currentText}/>
          </div>
          <NewsFeed news={news}/>
        </div>
      </motion.div>
  )
};
  
export default NewsPage;