import {React,  useEffect, useState} from 'react';
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import Loading from "./components/Loading"
import AnimatedRoutes from './components/AnimatedRoutes';


import "./styles.css";
import "typeface-roboto-mono";
import ColorModal from './components/ColorModal.js';

export default function App() {

let sessionPeople = JSON.parse(sessionStorage.getItem('people'));
let sessionProjects = JSON.parse(sessionStorage.getItem('projects'));
let sessionNews = JSON.parse(sessionStorage.getItem('news'));
let sessionPublications = JSON.parse(sessionStorage.getItem('publications'));
let sessionWorkAreas = JSON.parse(sessionStorage.getItem('workAreas'));
let sessionDataUtilities = JSON.parse(sessionStorage.getItem('utilities'));

const [isLoaded, setIsLoaded] = useState(false)

let mainData={};

const addArrayToMainData = (dataArray, key) => {
  if (dataArray.length > 0) {
    mainData[key] = dataArray;
    sessionStorage.setItem(`${key}`, JSON.stringify(dataArray));
  }
}
  
useEffect(() => {
  async function GetData(){
  if (sessionPeople===null || sessionProjects===null || sessionNews===null || sessionPublications===null){
    //console.log(" FETCHING  FETCHING  FETCHING  FETCHING  FETCHING  FETCHING  FETCHING ");
  Promise.all([
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/people?per_page=100'),
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/projects?per_page=100'),
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/publications?per_page=100'),
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/news?per_page=100'),
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/workareas?per_page=100'),
     fetch('https://backend.digitalanthropologylab.org/wp-json/acf/v3/utility?per_page=100'),
  ])
    .then(([resPeople, resProjects, resPublications, resNews, resWorkAreas,resUtilities]) => 
      Promise.all([resPeople.json(), resProjects.json(),resPublications.json(),resNews.json(),resWorkAreas.json(),resUtilities.json()])
    )
    .then(([dataPeople, dataProjects, dataPublications, dataNews, dataWorkAreas, dataUtilities]) => {
      //console.log("dataPeople before",dataPeople);

      addArrayToMainData(extractPeopleData(dataPeople),"people")
      addArrayToMainData(extractProjectWorkAreas(dataProjects), "projects");
      addArrayToMainData(sortByYear(dataPublications), "publications");
      addArrayToMainData(dataNews, "news");
      addArrayToMainData(dataWorkAreas, "workAreas");
      addArrayToMainData(dataUtilities, "utilities");
      
      setIsLoaded(true);
    })
    .catch((err)=>{
      //console.log(err);
      //console.log("FETCH ERROR");
      setTimeout(() => {
        GetData();
      }, 19000);
    });
} else {
  //setCombinedData(mainData)
  setIsLoaded(true);
}
}
GetData();
}, []);

function sortByYear(array) {
  array.sort((a, b) => b.acf.year - a.acf.year);
  // if you want to sort in descending order, use the following line instead:
  // array.sort((a, b) => b.acf.year - a.acf.year);
  return array;
}

const extractPeopleData = (array) => { 
  return array.map((element) => {
    const workAreas = (element.acf.workareas !== false) ? element.acf.workareas.map((area) => { return { id: area.ID, title: area.post_title }; }) : [];
    const projects = (element.acf.related_projects !== false) ? element.acf.related_projects.map((area) => { return { id: area.ID, title: area.post_title }; }) : [];      
    const publications = (element.acf.related_publications !== false) ? element.acf.related_publications.map((area) => { return { id: area.ID, title: area.post_title }; }) : [];  
      
      return {
        ...element,
        acf: {
          ...element.acf,
          workAreas: workAreas,
          relatedProjects:projects,
          relatedPublications:publications,
        },
      };
  });
};


const extractProjectWorkAreas = (array) => {
  return array.map((element) => {
    try {
      const workAreas = element.acf.workareas.map((area) => area.post_title);
      return {
        ...element,
        acf: {
          ...element.acf,
          workAreas,
        },
      };
    } catch (error) {
      console.log
      (`Error retrieving work areas for ${element.acf} with ID ${element.id}: ${error.message}`
      );
      return {
        ...element,
        acf: {
          ...element.acf,
          workAreas: [],
        },
      };
    }
  });
};


const extractPresAuthor = (array)=>array.map(element => { // Loop through each element in the array of objects
  try{
    let updatedElement = {};
      const postAuthor = element.acf.author.map(el => el.post_title);   // Extract the work areas from the acf object
      postAuthor.forEach(author => {
        const authorWords = author.split(" ");
        const slicedWords = authorWords.slice(0, -1);
        const slicedString = slicedWords.join(" ");

        updatedElement = {   // Create a new object with the updated work areas array
          ...element,
          acf: {
            ...element.acf,
            author: slicedString
          }
        }
      });
    return updatedElement;
  }
  catch(error){
      //console.log(`Error retrieving work areas for ${element.acf} with ID ${element.id}: ${error.message}`);
      const updatedelement = {   // Create a new object with the updated work areas array
        ...element,
        acf: {
          ...element.acf,
          author: []
        }
      }
    return updatedelement;
  }
});



  return (
    <div className="App">
	      <div className="shape-blob one"></div>
	      <div className="shape-blob two"></div>
        <div className="shape-blob three"></div>
        {isLoaded?
        <>
          <Navbar/>
            <AnimatedRoutes people={sessionPeople} projects={sessionProjects} publications={sessionPublications} news={sessionNews} workAreas={sessionWorkAreas} utilities={sessionDataUtilities}/>
            <ColorModal />
          <Footer/>
        </>
          :
        <Loading/>}
    </div>
  );
}

