import React from "react";
import "./PublicationsPage.css"
import { motion } from "framer-motion";
import Header from "../components/Header";
import AddInnerHtml from "../helpers/AddInnerHtml";

const PublicationCard = ({ publication }) => {
  return (
    <div className="publication-card">
      <AddInnerHtml text={publication.acf.publication} classNames={"publication-card-content"}/>      
    </div>
  );
};

function PublicationsPage(props) {

    const {publications} = props;
    
  // Group publications by year
  const publicationsByYear = publications.reduce((acc, pub) => {
    const year = new Date(pub.acf.year).getFullYear();
    acc[year] = acc[year] || [];
    acc[year].push(pub);
    return acc;
  }, {});

return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
      <Header text={props.text}/>
      <div className="container">
      <div className="publication-container">
        {Object.keys(publicationsByYear)
          .sort()
          .reverse()
          .map((year) => (
            <>
            <div className="publication-wrapper" key={year}>
              <text className="publication-year">{year}</text>
              <div className="publication-cards">
                {publicationsByYear[year].map((pub) => (
                  <>
                  <PublicationCard key={pub.id} publication={pub} />
                  </>
                ))}
              </div>
            </div>
            </>
          ))}
      </div>
      </div>
    </motion.div>
  );
};

export default PublicationsPage;
