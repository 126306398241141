import React from 'react'
import { NavLink } from 'react-router-dom'

function NavBarTopMenu({showMenu}) {
  return (
    <ul className={`menu menu-bar-desktop ${showMenu?"active":""}`}>
    <li>
          <NavLink to="/">
            <h3>
            Home
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/thelab">
            <h3>
            The Lab
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/news">
            <h3>
            News
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/workareas">
            <h3>
            Work Areas
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/projects">
            <h3>
            Projects
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/people">
            <h3>
            People
            </h3>
          </NavLink>
        </li>
        <li>
          <NavLink acticeclassname="active" to="/publications">
            <h3>
            Publications
            </h3>
          </NavLink>
          </li>
    </ul>
  )
}

export default NavBarTopMenu