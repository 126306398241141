import {useParams} from 'react-router';
import ProjectCardList from "../components/ProjectCardList"
import PeopleCardHover from '../components/PeopleCard.js';
import './WorkAreaSub.css';
import { motion } from "framer-motion";
import Header from '../components/Header';
import AddInnerHtml from '../helpers/AddInnerHtml';
import CopyrightTooltip from '../components/CopyrightTooltip';

function WorkAreaSub(props) {

  const { urlParam } = useParams();

  const {people, projects, workAreas} = props;

  const selectedWorkArea = workAreas.filter(workArea=>workArea.acf.titleUrl===urlParam);
  const currentWorkArea = selectedWorkArea[0].acf;



  let filteredProjects= projects.filter((project)=>{
    return(project.acf.workAreas.includes(currentWorkArea.title))
    
  }
  )
  

  const relatedPeople = people.filter(person => person.acf.workAreas.some(workArea => workArea.id === selectedWorkArea[0].id));


  //Sort related people's family names by ABC order
  relatedPeople.sort(function(a, b) {
    var nameA = a.acf.name.split(' ').pop().toUpperCase(); // get the family name from the last word of the name string and convert to uppercase
    var nameB = b.acf.name.split(' ').pop().toUpperCase(); // same for the second object
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });

  let cards = relatedPeople.map(people => 
      <PeopleCardHover
        people = {people.acf}
        projects = {projects}
        key = {people.id}
        />
    )

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{ duration: 0.8 }}>
      <Header text={props.text}/>
      <div className="container">
      <div className='info-wrapper'>
        <div className="sub-img-wrapper">
          <div className="img-tooltip-wrapper">
            <img className="wa-sub-img" src={currentWorkArea.imgUrl.url}/>
            <CopyrightTooltip classes="wa-tooltip" copyrightText={currentWorkArea.imgUrl.caption}/>
          </div>
        </div>
          <h1 className="subpage-title">{currentWorkArea.title}</h1>
          <div className="subpage-description">
          <AddInnerHtml text = {currentWorkArea.introduction}/>
          </div>
        </div>

      {filteredProjects.length>0?
        <section id="projects" style={{width:"100%"}}>
          <h1 className="section-title sub">Related Projects</h1>
            <ProjectCardList projects={filteredProjects}/>
        </section>
      :
        null
      }

      {relatedPeople.length>0?
        <section id="people" style={{width:"100%"}}>
          <h1 className="section-title sub">Related People</h1>
          <div className="card-container">
            {cards}
          </div>
        </section>
        :
        null
      }
      </div>

      </motion.div>
  )


}
export default WorkAreaSub;