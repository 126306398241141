import React, { useState, useEffect } from "react";
import "./Header.css";

const Header = (props) => {

  const textInput = props.text.toUpperCase();
  const textArr = [];
  const changeLetters=["A","I","O","E","T","S","Z"]
  let changeIndexes=[];

  for (let i = 0; i < textInput.length; i++) {
    const char = textInput.charAt(i);
    if (changeLetters.includes(char)) {
      changeIndexes.push(i);
    }
  }

  for (let i = 0; i < textInput.length; i++) {
    const char = textInput.charAt(i);
    if (char === " ") {
      textArr.push(char);
    } else {
      textArr.push(char.toUpperCase());
    }
  }


  const [text, setText] = useState(textArr);
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(new Date().getTime()/1000)
    }, 2000);
    return () => clearInterval(interval);
  }, [seconds]);

  function fChangeLetters(){
    const index = Math.floor(Math.random() * changeIndexes.length);
    const letter = textArr[changeIndexes[index]];
    let newText = textArr;

    if (letter === "I") {
      newText[changeIndexes[index]] = "1";
    } else if (letter === "A") {
      newText[changeIndexes[index]] = "4";
    } else if (letter === "O") {
      newText[changeIndexes[index]] = "0";
    } else if (letter === "E") {
      newText[changeIndexes[index]] = "3";
    }  else if (letter === "S") {
      newText[changeIndexes[index]] = "5";
    }   else if (letter === "T") {
      newText[changeIndexes[index]] = "7";
    }   else if (letter === "Z") {
      newText[changeIndexes[index]] = "2";
    } 
    
    else if (letter === "1") {
      newText[changeIndexes[index]] = "I";
    } else if (letter === "4") {
      newText[changeIndexes[index]] = "A";
    } else if (letter === "0") {
      newText[changeIndexes[index]] = "O";
    } else if (letter === "3") {
      newText[changeIndexes[index]] = "E";
    }  else if (letter === "5") {
      newText[changeIndexes[index]] = "S";
    }   else if (letter === "7") {
      newText[changeIndexes[index]] = "T";
    }   else if (letter === "2") {
      newText[changeIndexes[index]] = "Z";
    } 
    setText(newText);
  }

  useEffect(() => {
    for (let index = 0; index < changeIndexes.length/2; index++) {
      setTimeout(() => {
        fChangeLetters();
      }, 2000);
    }
  }, [seconds]);



  return (
    <div className="digital-anthropology-lab">
      <h1 id="center">{text}</h1>
    </div>
  );
};


export default Header;
