import React from 'react';
import { useNavigate } from "react-router-dom";
import "./WorkAreaList.css"
import { motion } from "framer-motion"


function WorkAreaCard(props) {
    const {homepage, id}= props;
    const {title, titleUrl, imgUrl} = props.workArea;


    const navigate = useNavigate();
    
    return (
      <>
      <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} className="card-item" key={id} onClick={() => navigate(`/workareas/${titleUrl}`)}>
        <div className="hover-tint wa">
          <img className="wa-card-img" src={imgUrl.url}></img>
        </div>
        {homepage?null:
        <div className="card-title">
          <h3>{title}</h3>
        </div>
        }
        </motion.div>
      </>
    );
  }

function WorkAreaList(props) {

  const {workAreas, homepage} = props;
  
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} className="card-container">
      {workAreas.map((workArea) => (
        <WorkAreaCard 
          key={workArea.id} 
          id={workArea.id}
          workArea = {workArea.acf}
          homepage = {homepage}
        />
      ))}
    </motion.div>
  );
}

export default WorkAreaList;
